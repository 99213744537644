'use strict'
import { Hangman } from './hangman'
import  getPuzzle  from './requests'

let game1;

const renderGame = () => {
    const wordElement = document.querySelector("#word");
    const statusMessageElement = document.querySelector("#status-message");
    wordElement.innerHTML = '';
    const wordArray = game1.puzzle.split('');

    wordArray.forEach(letter => {
        const element = document.createElement('span');
        element.textContent = letter;
        wordElement.appendChild(element);
    });
    
    statusMessageElement.textContent = game1.statusMessage;
}

window.addEventListener("keypress", (e) => {
    game1.makeGuess(e.key);
    renderGame();
})

const startGame = async () => {
    const puzzle = await getPuzzle(2);
    game1 = new Hangman(puzzle, 5);
    renderGame();
}

document.querySelector('#refresh-game').addEventListener("click", startGame);

startGame();