'use strict'

class Hangman {
    constructor (word = "A", guesses = 1) {
        this.word = word.toLowerCase().split("");
        this.guessedLetters = [];
        this.guessesLeft = guesses;
        this.gameStatus = "playing" //status option = playing, failed or won
    };

    updateGameStatus () {
        const isFinished = this.word.every((letter) => {
            return this.guessedLetters.includes(letter) || letter === ' ';
        });

        if(this.guessesLeft <= 0) {
            this.gameStatus = "failed";
        } else if(isFinished) {
            this.gameStatus = "won";
        } else {
            this.gameStatus = "playing";
        }
    }

   get statusMessage () {
        let statusMessage = "";
        switch (this.gameStatus) {
            case "failed": 
                statusMessage =  `Nice try - The word was "${this.word.join('')}"`;
                break;
            case "won": 
                statusMessage =  `Congratulations - you have correctly guessed the word`;
                break;
            case "playing": 
                statusMessage =  `You have ${this.guessesLeft} guesses left.`;
                break;
            default: 
                statusMessage =  "";
                break;
        };
        return statusMessage;
    }

    get puzzle () {
        let output = "";
        this.word.forEach((letter) => {
            if(letter != " ") {
                output += this.checkGuesses(letter) ? letter : "*";
            } else {
                output += " ";
            }
        });
        return output;
    };

    checkGuesses(letter) {
        if(this.guessedLetters.length > 0) {
            return this.guessedLetters.includes(letter.toLowerCase());
        } else {
            return false;
        }
    };

    makeGuess  (letter) {
        if(this.gameStatus !== 'playing') {
            return;
        }

        letter = letter.toLowerCase();
        if(!this.checkGuesses(letter)) {
            this.guessedLetters.push(letter);
            this.decrementGuess(letter);
        } 
        this.updateGameStatus();
    };

    decrementGuess (letter) {
        if(!this.word.includes(letter) && this.guessesLeft > 0) {
            this.guessesLeft --;
        }
    };
}

export {
    Hangman
}
